<template>
  <div class="page">
    <h2>搜索商品</h2>
    <chooseGoods @handleChange="handleChange"/>
    <div class="fucView">
      <el-button type="primary" :disabled="choosedGoodsList.length == 0" @click="setOnce">逐一修改</el-button>
      <el-button type="primary" :disabled="choosedGoodsList.length == 0" @click="setLot">批量修改</el-button>
    </div>
  </div>
</template>

<script>
import chooseGoods  from "@/views/tools/components/chooseGoods";
export default {
  name: "setGoodsBase",
  components: {
    chooseGoods
  },
  data () {
    return {
      choosedGoodsList: [],
    }
  },
  methods:{
    handleChange(value) {
      this.choosedGoodsList = value;
    },
    setOnce(){
      let self = this;
      let goodsList = self.choosedGoodsList
      self.$router.push({
        name: 'setOnce',
        params: {
          goodsList: goodsList
        }
      })

    },
    setLot(){
      let self = this;
      let goodsList = self.choosedGoodsList
      self.$router.push({
        name: 'setLot',
        params: {
          goodsList: goodsList
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  //margin: 20px;
}
.fucView {
  display: flex;
  align-items: center;
  margin-left: 120px;
  margin-top: 10px;
}
.el-form-item {
  margin-bottom: 10px;
}

</style>